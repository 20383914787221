<!--
 * @Author: zhanggai
 * @Description: 影片点播页面,影片列表
 -->
<template>
  <div class="onDemand">
    <div class="header">
      <!-- <Header
        @search="searchValue"
        :show-explain="false"
        explain-text="点播说明"
        @explain="handleExplain"
        style="padding: 0"
        ref="header"
      /> -->
      <van-nav-bar :title="''">
        <template slot="left">
          <div @click="toSelectPoint()" class="point-title">
            <van-icon name="location" color="#686B73"/>
            {{
              point ? point.name : '未知放映点'
            }}
          </div>
        </template>
        <template slot="right">
          <div style="display: flex; justify-content: end; margin-right: 10px">
            <!-- 搜索查询 -->
            <van-search
                v-model="filters.name"
                show-action
                placeholder="请输入搜索关键词"
                size="small"
                style="width: 90%"
                @search="getFilmList(true)"
            >
              <template #action>
                <div @click="getFilmList(true)" style="color: #1492ff">
                  搜索
                  <!-- <van-button type="info" round block  style="width: 12vw;" size="mini">
                    <van-icon name="search" color="#fff" />
                  </van-button> -->
                </div>
              </template>
            </van-search>
          </div>
        </template>
      </van-nav-bar>
    </div>

    <!--  影片筛选器-->
    <van-dropdown-menu active-color="#1989fa">
      <van-dropdown-item
          v-model="filters.type"
          :options="typeList"
          @change="getFilmList(true)"
      />
      <van-dropdown-item
          v-model="filters.year"
          :options="yearList"
          @change="getFilmList(true)"
      />
      <van-dropdown-item
          v-model="filters.project"
          :options="projectList"
          @change="getFilmList(true)"
      />
      <van-dropdown-item
          v-model="filters.sort"
          :options="sortList"
          @change="getFilmList(true)"
      />
    </van-dropdown-menu>
    <!--  影片列表-->
    <div class="filmList">
      <van-list
          :loading="showLoading"
          class="van-clearfix"
          finished-text="没有更多了"
          :finished="finished"
          @load="nextFilms"
      >
        <van-row
            class="filmItem float-item"
            v-for="(movie, index) in filmList"
            :key="index"
        >
          <movieCard
              :movie-img="movie.img"
              :movie-title="movie.title"
              :movie-score="movie.score"
              :movie-play-num="movie.playNum"
              :movie-type="movie.type"
              :movie-id="movie.id"
              :movie-length="movie.length"
              :movie-description="movie.description"
              :onDemand="movie.onDemand"
              :movie-year="movie.year"
              @select="handleMovie"
          ></movieCard>
        </van-row>
        <div v-if="filmList.length < 1">
          <van-empty description="暂无影片可选"/>
        </div>
      </van-list>
    </div>
    <!--    {{finished}}-->
  </div>
</template>

<script>
// import Header from "@comp/header";
import movieCard from '@comp/movieCard'
import {Dialog} from 'vant'
import {api} from '@/api/index'
import {SetPvAndUv} from '@/mixins/PvAndUv'
import {getUserPoint} from '@/utils/util'

export default {
  name: 'onDemand',
  mixins: [SetPvAndUv],
  components: {
    // Header,
    movieCard,
  },
  data() {
    return {
      // 上一页面
      from: '',
      // 点播人群类型
      personType: 0,
      showLoading: false,
      finished: false,
      // 总页数
      pages: 1,
      pageNo: 0,
      filters: {
        name: '',
        type: '',
        year: '',
        project: '',
        sort: 0,
      },
      typeList: [
        {text: '类型', value: ''},
        // 故事片
        {text: '故事片', value: '故事片'},
        // 科教片
        {text: '科教片', value: '科教片'},
        // 戏曲片
        {text: '戏曲片', value: '戏曲片'},
        // 纪录片
        {text: '纪录片', value: '纪录片'},
        // 动画片
        {text: '动画片', value: '动画片'},
        // 美术片
        {text: '美术片', value: '美术片'},
        // 其它
        {text: '其它', value: '其它'},
      ],
      yearList: [],
      // 专题列表
      projectList: [
        {
          text: '专题',
          value: '',
        },
      ],
      // 默认排序
      sortList: [
        {
          text: '排序',
          value: 0,
        },
        {
          text: '最热',
          value: 1,
        },
        {
          text: '播放',
          value: 2,
        },
        {
          text: '评分',
          value: 3,
        },
        {
          text: '最新',
          value: 4,
        },
      ],
      filmList: [],
      scroll: 0,
      point: null,
    }
  },
  beforeRouteEnter(to, from, next) {
    console.log('beforeRouteEnter', to, from, next)
    var cacheList = ['/filmDetail', '/submitOnDemand', '/groupOnDemand']
    switch (from.path) {
      case '/ruralFilm':
        sessionStorage.removeItem('filters');
        break;
        //来自影片点播成功
        // case '/onDemand/success':
        //   this.$router.replace({path: '/playRecord'});
        //   break;
    }

    next((vm) => {
      // console.log('TTTTTTTTT',vm.$route);
      if (cacheList.indexOf(from.path) == -1) {
        vm.filmList = []
        vm.filters = {
          name: '',
          type: vm.$route.query.t || '', //获取播放类型
          year: '',
          project: '',
          sort: 0,
        }
        vm.pageNo = 1
        vm.getFilmList()
        vm.getThemeData()
      }
    })
  },
  activated() {
    console.log('activated')
    this.point = getUserPoint()

    if (this.scroll > 0) {
      var body = document.getElementsByClassName('filmList')[0]
      body.scrollTo(0, this.scroll) // 滚动到缓存的scroll值的位置
      this.scroll = 0
    }
    this.playType = this.getUrlParam('t') //获取播放类型
    this.filters.project = this.getUrlParam('themeName') //获取播放专题
    window.addEventListener('scroll', this.handleScroll, true)
  },
  deactivated() {
    window.removeEventListener('scroll', this.handleScroll, true) // 页面退出时关闭事件，防止其他页面出现问题
    // this.$refs.header.searchValue = ''
  },
  mounted() {
    this.point = getUserPoint()

    // 动态生成最近10年
    const year = new Date().getFullYear()
    const Arr = []
    Arr.push({text: '年份', value: ''})
    Array.from({length: 15}, (v, k) => year - k).forEach((item) => {
      Arr.push({text: item, value: item})
    })
    Arr.push({text: '更早', value: '0'})
    this.yearList = Arr
    this.getThemeData()
    this.playType = this.getUrlParam('t') //获取播放类型
    // 判断filters是否存在如果存在就赋值
    if (sessionStorage.getItem('filters')) {
      this.filters = JSON.parse(sessionStorage.getItem('filters'))
    }
    // this.getFilmList();
  },
  methods: {
    // 跳转到选择放映点页面
    toSelectPoint() {
      this.$router.replace({
        path: '/ruralFilm/selectPoint',
        query: {form: this.$route.path, ...this.$route.query},
      })
    },
    handleScroll() {
      // 记录滚动位置
      this.scroll = document.getElementsByClassName('filmList')[0].scrollTop
    },
    //获取专题列表
    getThemeData() {
      if (!this.point) {
        console.log('未选择放映点')
        return
      }
      api.getShowPointThemeList({
        showPointId: this.point.id || 0,
      }).then((res) => {
        this.projectList = [
          {
            text: '专题',
            value: '',
          },
        ]
        res.forEach((item) => {
          if (item.status === 1) {
            this.projectList.push({
              text: item.themeName,
              value: item.themeName,
            })
          }
        })
      })
    },
    // 获取影片列表
    async getFilmList(reload = false) {
      if (reload) {
        this.filmList = []
        this.pageNo = 1
        this.finished = false
      }
      if (!this.point) return
      await api
          .getFilmList({
            type: this.filters.type,
            name: this.filters.name,
            sort: this.filters.sort,
            themeName: this.filters.project,
            year: this.filters.year,
            pageNo: this.pageNo,
            pageSize: 10,
            showPointId: this.point.id || 0,
          })
          .then((res) => {
            const {records} = res
            records.map((item) => {
              this.filmList.push({
                id: item.id,
                title: item.name,
                score: item.score,
                playNum: item.clickCount,
                type: item.type,
                img: item.filmPicture,
                length: item.filmLength,
                description: item.synopsis,
                year: item.year.toString() || '',
                // onDemand: true
              })
            })
            if (records.length === 0) {
              this.finished = true
            }
          })
    },
    nextFilms() {
      this.loading = true
      this.pageNo += 1
      this.getFilmList()
    },
    handleMovie(movie) {
      // 跳转之前保存筛选条件
      sessionStorage.setItem('filters', JSON.stringify(this.filters))
      // 跳转到影片详情
      this.$router.push({
        path: '/ruralFilm/filmDetail/' + movie.movieId,
        query: {
          id: movie.movieId,
          title: movie.movieTitle,
          playType: this.playType,
        },
      })
    },
    // 搜索
    searchValue(value) {
      this.filters.name = value
      this.getFilmList(true)
    },
    // 获取URL参数
    getUrlParam(name) {
      return this.$route.query[name] || ''
    },
    handleExplain() {
      Dialog.alert({
        title: '点播说明',
        message:
            '\n' +
            '1、点播提交后，当地公益放映放映公司将收到您的点播需求。公益放映放映公司将综合各村点播结果和实际情况，进行放映安排。\n' +
            '\n' +
            '2、点播提交成功将自动发起一场拼团，您可邀请其他用户一起点播，每人每场只可以一起点播一次。拼团人数越多的场次，将优先安排进行放映。\n' +
            '\n' +
            '3、具体放映安排以放映公告为准，如有疑问，请咨询当地公益放映放映公司。',
        confirmButtonText: '关闭',
      })
    },
  },
}
</script>

<style scoped lang="less">
.header {
  //padding: 0 10px;
}

.point-title {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 31vw;
  white-space: nowrap;
  font-size: 16px;
  color: #686b73;
}

::v-deep .van-dropdown-menu__bar {
  background: #f6f7f8;
}

.onDemand {
  overflow-y: hidden;
  height: 100vh;
}

.filmList {
  margin-top: 16px;
  position: relative;

  overflow-y: scroll;
  height: 86%;
}

.filmItem {
  padding: 0px 10px 0 10px;
}
</style>
